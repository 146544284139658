// Single / multiple select with search and sorting, tags components
// based on https://github.com/Choices-js/Choices

// Reboot plugin's styles

// stylelint-disable selector-class-pattern
.choices {
  margin-bottom: 0;
}

.choices[data-type*="select-one"] {
  &::after {
    display: none;
  }

  .form-select {
    padding-right: 3.25rem;
  }

  .choices__button {
    right: 0;
    left: auto;
    margin: {
      right: 2rem;
      left: 0;
    }
    background-size: 9px;
    box-shadow: none !important;
    opacity: .4;
    transition: opacity .15s ease-in-out;

    &:hover {
      opacity: .8;
    }
  }
}

.filter-select:has(.choices__item:not(.choices__placeholder)) {
  --#{$prefix}form-control-border-color: #{$input-focus-border-color};
}

.choices[data-type*="select-multiple"] .form-select,
.choices[data-type*="text"] .form-select {
  padding: $input-btn-padding-y $input-btn-padding-y ($input-btn-padding-y - .25rem) $input-btn-padding-y;
  background-image: none;

  &.form-select-lg {
    padding: $input-btn-padding-y-lg $input-btn-padding-y-lg ($input-btn-padding-y-lg - .25rem) $input-btn-padding-y-lg;
  }

  &.form-select-sm {
    padding: $input-btn-padding-y-sm $input-btn-padding-y-sm ($input-btn-padding-y-sm - .25rem) $input-btn-padding-y-sm;
  }
}

.choices__placeholder {
  color: $input-placeholder-color;
  opacity: 1;
}

.is-focused .form-select {
  border-color: var(--#{$prefix}form-control-focus-border-color);
}

.is-disabled .form-select {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
  border-style: dashed;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  z-index: 10;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: $dropdown-spacer * 1.25 0 !important;
  font-size: $dropdown-font-size;
  background-color: $dropdown-bg;
  border: $dropdown-border-width solid $dropdown-border-color !important;
  @include border-radius($dropdown-border-radius !important);
  @include box-shadow($dropdown-box-shadow !important);

  .choices__placeholder {
    display: none !important;
  }

  .choices__list {
    max-height: 240px;
  }
}

.choices__list--single {
  display: flex;
  padding: 0;

  .choices__item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  display: flex;
  align-items: center;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
  @include border-radius($dropdown-item-border-radius !important);

  &.is-highlighted {
    color: $dropdown-link-active-color;
    background-color: $dropdown-link-active-bg;
  }
}

.choices .choices__input {
  padding: {
    top: .25rem;
    right: 0;
    bottom: .25rem;
    left: .375rem;
  }
  margin: 0;
  margin-bottom: .25rem;
  color: $input-color;
  background-color: transparent !important;

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}

.choices:not([data-type*="select-multiple"]):not([data-type*="text"]) .choices__input {
  padding-left: 1.75rem !important;
  margin: {
    top: -.375rem !important;
    bottom: $dropdown-spacer * 1.25 !important;
  }
  background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 32 32' fill='#9ca3af'><path d='m21.6 20.4-3.8-3.8c1.2-1.5 2-3.5 2-5.6 0-4.9-4-8.9-8.9-8.9S2 6.1 2 11s4 8.9 8.9 8.9c2.1 0 4.1-.8 5.6-2l3.8 3.8c.3.3.9.3 1.2 0 .5-.4.5-1 .1-1.3M16.1 16l-.1.1c-1.3 1.2-3 2-5 2-3.9 0-7.1-3.2-7.1-7.1S7.1 3.9 11 3.9s7.1 3.2 7.1 7.1c0 1.9-.8 3.7-2 5'/></svg>")) no-repeat .125rem .75rem;
  border-color: var(--#{$prefix}border-color) !important;
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  &::after {
    flex-shrink: 0;
    width: $dropdown-font-size * 1.5;
    height: $dropdown-font-size * 1.5;
    margin: {
      top: .1875rem;
      right: -.25rem;
      left: auto;
    }
    content: "";
    background-color: currentcolor;
    opacity: 0;
    -webkit-mask: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/></svg>")) no-repeat 50% 50%; // stylelint-disable-line property-no-vendor-prefix
    mask: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/></svg>")) no-repeat 50% 50%;
    -webkit-mask-size: cover; // stylelint-disable-line property-no-vendor-prefix
    mask-size: cover;
  }

  &.is-highlighted::after {
    opacity: .85;
  }
}

.choices__heading {
  padding: 1rem $dropdown-item-padding-x .75rem;
  margin-bottom: $dropdown-spacer * 1.25;
  font: {
    size: $font-size-sm;
    weight: $headings-font-weight;
  }
  color: var(--#{$prefix}heading-color);
  border-color: var(--#{$prefix}border-color);
}

.choices.is-disabled .choices__list--multiple .choices__item,
.choices__list--multiple .choices__item {
  background-color: var(--#{$prefix}emphasis-color);
  border-color: var(--#{$prefix}emphasis-color);
  margin: {
    right: .25rem;
    bottom: .25rem;
  }

  .choices__button {
    padding: {
      right: .375rem;
      left: 1rem;
    }
    margin: {
      right: -.25rem;
      left: .5rem;
    }
    border: {
      right: 0;
      left: 1px solid rgba($white, .3);
    }
  }

  &.is-highlighted {
    background-color: var(--#{$prefix}secondary-color);
    border-color: var(--#{$prefix}secondary-color);
  }
}

.choices.is-disabled .choices__list--multiple .choices__item {
  opacity: .45;
}


// Validation

.was-validated .choices:has(.form-select:invalid),
.choices:has(.is-invalid) {
  .form-select {
    border-color: var(--#{$prefix}form-invalid-border-color);
  }
  ~ .invalid-feedback,
  ~ .invalid-tooltip {
    display: block;
  }
}
.was-validated .choices:has(.form-select:valid),
.choices:has(.is-valid) {
  ~ .valid-feedback,
  ~ .valid-tooltip {
    display: block;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .choices[data-type*="select-one"] .choices__button {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
    }

    .choices.is-disabled .choices__list--multiple .choices__item,
    .choices__list--multiple .choices__item {
      color: $dark;

      .choices__button {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
        border-color: $gray-300;
      }
    }

    .filter-select:has(.choices__item:not(.choices__placeholder)) {
      --#{$prefix}form-control-border-color: #{$input-focus-border-color-dark};
    }

    .choices:has([data-bs-theme="light"]) {
      .form-select {
        --#{$prefix}form-control-bg: #{$input-bg};
        --#{$prefix}form-control-border-color: #{$input-border-color};
        --#{$prefix}form-control-focus-bg: #{$input-focus-bg};
        --#{$prefix}form-control-focus-border-color: #{$input-focus-border-color};
        --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator)};
      }

      .choices__button {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
      }

      .choices.is-disabled .choices__list--multiple .choices__item,
      .choices__list--multiple .choices__item {
        color: $white;

        .choices__button {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
          border-color: rgba($white, .3);
        }
      }

      .filter-select:has(.choices__item:not(.choices__placeholder)) {
        --#{$prefix}form-control-border-color: #{$input-focus-border-color};
      }
    }
  }
}
// stylelint-enable selector-class-pattern

// Extend default Bootstrap's card compopnent

// Header navs

.card-header-nav-underline {
  --#{$prefix}nav-link-padding-y: calc(1.25 * var(--#{$prefix}card-cap-padding-y));

  margin: calc(-1 * var(--#{$prefix}card-cap-padding-y)) calc(-.125 * var(--#{$prefix}card-cap-padding-x));
}

.card-header-tabs {
  --#{$prefix}nav-tabs-padding: var(--#{$prefix}card-cap-padding-y);

  margin: calc(-1 * var(--#{$prefix}card-cap-padding-y)) calc(-1 * var(--#{$prefix}card-cap-padding-x));
  @include border-bottom-radius(0);
}


// Highlighted card

.card.is-highlighted {
  border-color: var(--#{$prefix}component-active-color);
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .product-card:hover .product-card-button {
      @extend .btn-primary;
    }
  }
}

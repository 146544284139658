// Interactive map component

@include media-breakpoint-up(lg) {
  .map-section {
    position: sticky;
    top: 76px;
    height: calc(100vh - 76px);
  }

  .map-section,
  .listings-section {
    width: 50%;
  }
}


// Marker

.map-marker-price {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: $spacer * .375 $spacer * .75;
  font: {
    family: $font-family-sans-serif;
    size: $font-size-xs;
    weight: $font-weight-medium;
  }
  color: $gray-800;
  background-color: $white;
  @include border-radius($border-radius-sm);
  @include box-shadow($box-shadow);
  transition: color .2s ease-in-out, background-color .2s ease-in-out;
  transform: translateX(-50%);
}

.map-marker {
  width: auto !important;
  height: auto !important;
}

.is-highlighted .map-marker-price {
  color: $white;
  background-color: var(--#{$prefix}primary);
}


// Popup

.leaflet-popup {
  margin: {
    bottom: 28px;
    left: 5px;
  }
}

.leaflet-popup-content-wrapper {
  min-width: 280px;
  padding: 0;
  overflow: hidden;

  .leaflet-popup-content {
    width: 100% !important;
    margin: 0 !important;
    color: $white;
  }
}

.leaflet-container {
  font-family: $font-family-sans-serif;

  a.leaflet-popup-close-button { // stylelint-disable-line selector-no-qualifying-type
    top: $spacer * .5;
    right: $spacer * .5;
    left: auto;
    z-index: 10;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: $font-size-sm;
    line-height: $line-height-base;
    color: $gray-700;
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    @include box-shadow($box-shadow-sm);

    &:hover {
      color: $gray-900;
    }
  }
}
.leaflet-popup:focus,
.leaflet-marker-icon:focus { outline: none; }
.leaflet-popup-tip-container {
  backface-visibility: hidden;
}


// Zoom controls

/* stylelint-disable selector-no-qualifying-type */
.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: 0;
    @include box-shadow($box-shadow);
  }
  .leaflet-bar a {
    font-weight: $font-weight-normal;
    line-height: 26px;
    color: $gray-700;
    transition: $nav-link-transition;

    &:first-child {
      border-bottom-color: $gray-200;
      border-top-left-radius: $border-radius-sm;
      border-top-right-radius: $border-radius-sm;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }
    &:hover {
      color: $gray-900;
      background-color: $white;
    }
    &.leaflet-disabled {
      color: $gray-400;
      background-color: $white;
    }
  }
}
/* stylelint-enable selector-no-qualifying-type */
